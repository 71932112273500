import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "ricardos",
  casinoId: 48,
  GA_TRACKING:'G-B2NFQ99Y2N',

  rivalChatGroupName: "Ricardo's Casino",
  prettyName: "Ricardo's Casino",
  contactEmail: "support@ricardoscasino.net",
  docsEmail: "documents@ricardoscasino.email",


  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/brands/ricardos/promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/brands/ricardos/promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/brands/ricardos/promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/brands/ricardos/promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/brands/ricardos/promo.png"}
  ],


  //SEO
  metaDescription: "Join Ricardo's Online Casino and play top slots, table games, and live dealers with exciting bonuses and a rewarding loyalty program. Enjoy secure play on desktop or mobile. Join now for online casino gaming and safe real cash payouts even in crypto!",
  logoName: "ricardos-online-casino-online-casino-logo.png",
  logoAltText: "Ricardo's Online Casino logo with vibrant pink and blue script font popular for slots blackjack and exclusive VIP casino bonuses."
};


